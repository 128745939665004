import logo from './logo.svg';
import NavSideBar from './components/NavSideBar';
import './App.css';

function App() {
  return (
    <div className="App">
      <NavSideBar></NavSideBar>
    </div>
  );
}

export default App;
