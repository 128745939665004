import { Button } from '@mui/material';
import AwsService from '../../apiServices/AwsService';
import { useState } from 'react';
import './NavSideBar.scss';

const NavSideBar = () => {

    const [pdfData, setPdfData] = useState(null);

    const getResume = async () => {
        const myResume =  await AwsService.getResume();
        setPdfData(myResume);
    }
    return (
        <div>
            <div className='description'>
                Clicking this button will demonstrate a solid understanding of the Full Stack process. This webwite has SSL encryption which can be seen by the fact that this is an HTTPS site. It uses an Nginx server running on a virtual machine to host
                the site and reverse proxy to a Node.js server running in the background. The virtual machine has a firewall to block certain connections, as well as a VPC for added security. The Node.js server is used to hide sensitive data, and route API calls to their
                destinations which need authentication. I then use a Serverless framework to set up AWS services that are used to store and retrieve this PDF document. These have all been locked down with authentication, as well as IAM policies.

            </div>
            <div className='retrieval-button'>
                <Button onClick={getResume} variant="contained" color="primary">
                    Retrieve Resume
                </Button>
            </div>
            <div className='resume'>
                {pdfData &&
                    <embed src={`data:application/pdf;base64,${pdfData}`} type="application/pdf" width="100%" height="100%"/>
                }
            </div>
        </div>
        
    )
}

export default NavSideBar;