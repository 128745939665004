import axios from 'axios';

class AwsService {
    getResume = async () => {
        try {
            const url = 'https://dufrenesoftware.com/api/hello';

            const responseObject = await axios.get(url);
            return responseObject.data;
        } catch (error) {
            console.log('THERE WAS AN ERROR: ', error);
        }
    }
}

export default new AwsService();